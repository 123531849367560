import { useState, useEffect } from 'react';
import './photos.scss';
import photo1 from '../image/slideshow/_MG_1554-Enhanced-NR.jpg';
import photo2 from '../image/slideshow/_MG_1973-Enhanced-NR.jpg';

const Photos = ({children}) => {

    const [photos, setPhotos] = useState(generatePhotos());

    function generatePhotos() {
        let srcs = [
            photo1, 
            photo2
        ]
        return srcs;
    }

    return (
        <div className={'photos'}>
            <div className={'header'}>
                <h1>Photos</h1>
            </div>
            <div className={'content'}>
                {photos.map((src, i) => {
                    return (
                        <div className={'image animate__animated animate__zoomIn'} key={i}>
                            <img src={src} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Photos;
