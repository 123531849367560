import logo from './logo.svg';
import Home from './pages/home';
import './App.scss';
import Nav from './component/nav';
import { useEffect, useState } from 'react';

function App({children}) {

  return (
    <div className={'app'}>
        <div className={'header'}>
            <Nav />
        </div>
        <div className={'main'}>
            {children}
        </div>
    </div>
  );
}

export default App;
