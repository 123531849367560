import { useState, useEffect } from 'react';
import './nav.scss';
import hamburger from '../SVG/hamburger.svg';
import bear from '../SVG/bruin-bear.svg';
import header from '../SVG/impactheader.svg';
import discord from '../SVG/discord.svg';

const Nav = ({}) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className={'nav'}>
            <div>
                {/* <button className={`hamburger ${isMenuOpen ?? ' open'}`}>
                    <img src={hamburger}/>
                </button> */}
                <div className={'logos'}>
                    <a href="/" style={{textDecoration: "none"}}><h1>AAAA @ UCLA</h1></a>
                </div>
            </div>
            <div className={'socials'}>
                <a href="https://ucla.edu" title="visit ucla.edu!">
                    <img style={{filter: 'brightness(0) invert(1)'}} src={bear} alt={"link to UCLA.edu"}/>
                </a>
                <a href="https://discord.com/invite/8GEGZ8VGwA" className={"discord"} title="join the Discord!">
                    <img src={discord} alt={"link to the Discord"}/>
                </a>
            </div>
        </div>
    )
}

export default Nav;
