import { useState, useEffect } from 'react';
import './home.scss';
import logo from "../AAAA_Logo.png";
import Slideshow from '../component/slideshow';

/**
 * 
 * Slideshow imports
 * 
 */
import banner from '../image/slideshow/_MG_1554-Enhanced-NR.jpg';

const Home = ({children}) => {
    return (
        <div className={'home'}>
            <div className={'banner animate__animated animate__backInDown'}>
                <img src={banner} loading='lazy'></img>
            </div>
            <div className={'content'}>
                <div className={'blurb animate__animated animate__backInLeft'}>
                    <h2>Furries at UCLA</h2>
                    <span>
                        We are the official UCLA chapter of the Anthropomorphic Animal Appreciation Association!
                    </span>
                </div>
                <div className={'blurb right animate__animated animate__backInRight'}>
                    <a href="/"><h2>Events</h2></a>
                    <a href="/photos"><h2>Photos</h2></a>
                </div>
            </div>
        </div>
    )
}

export default Home;
