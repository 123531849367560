import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import Home from './pages/home';
import App from './App';
import './index.css';

import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";
import Photos from './pages/photos';

const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Home />
      ),
    },
    {
        path: "/photos",
        element: (
          <Photos />
        ),
    },
    {
      path: "about",
      element: <div>About</div>,
    },
  ]);
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App>
        <RouterProvider router={router} />
    </App>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
